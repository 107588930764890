import { Link } from 'react-router-dom'

function Footer() {

  var year = new Date().getFullYear()


  return (
    <footer className='bg-link text-center '>
      <div className='container p-3'>
        <section className='mb-1'>
          <p>
          UHDSS education agency is Africa&apos;s best education agency for students who want to study aboard, immigration services, 
          visa services for visitors, business visa, Tourist visa and other Tourism Services.
          </p>
        </section>
      </div>

      <div className='text-center p-3'>
        ©  {`${year}`}  Copyright:
        <Link className='text-dark' to=''><strong> UHDSS</strong></Link>
      </div>

    </footer>
  )
}

export default Footer